import { j as a } from "./jsx-runtime-D0AQ1Mn7.js";
import { B as n } from "./Button-CAmI0_H8.js";
import { D as i } from "./Dialog.component-fr4P9TXE.js";
import { I as t } from "./Icon-vWfhOtJ0.js";
import { S as m } from "./Stack-B4QZt_R0.js";
import { T as s } from "./Text-CwTWgi3Y.js";
import { c as r } from "./utils-CJ9afRe1.js";
const d = {
  info: "BigExclamation",
  danger: "BigExclamationDestructive",
  warning: "BigExclamationWarning"
}, v = ({
  cancelButtonText: l = "Cancel",
  confirmButtonText: c = "Confirm",
  variant: o = "info",
  ...e
}) => /* @__PURE__ */ a.jsx(
  i,
  {
    onOpenChange: e.onOpenChange,
    open: e.open,
    children: /* @__PURE__ */ a.jsxs(
      i.Content,
      {
        withoutPortal: e.withoutPortal,
        overlayClassName: `${e.overlayClassName} z-full-screen`,
        "aria-describedby": e.ariaLabel,
        "aria-label": e.ariaLabel,
        className: r("pt-8 pb-5 max-w-[95%] sm:max-w-96 rounded relative z-full-screen", e.className),
        children: [
          e.onCloseButtonClick && /* @__PURE__ */ a.jsx(
            n,
            {
              onClick: e.onCloseButtonClick,
              variant: "secondary",
              size: "sm",
              circle: !0,
              className: "absolute top-0 right-0 p-0 mr-2 mt-2",
              children: /* @__PURE__ */ a.jsx(t, { name: "Cancel", width: 20, height: 20, strokeWidth: 2 })
            }
          ),
          /* @__PURE__ */ a.jsx(i.Title, { className: "hidden", children: e.ariaLabel }),
          /* @__PURE__ */ a.jsxs(i.Header, { className: "px-10 flex-1 items-center justify-center", children: [
            !e.icon && /* @__PURE__ */ a.jsx(
              t,
              {
                name: d[o],
                className: "text-black-10"
              }
            ),
            e.icon,
            /* @__PURE__ */ a.jsxs(m, { space: "sm", className: "pt-8", children: [
              e.title && /* @__PURE__ */ a.jsx(
                s,
                {
                  center: !0,
                  weight: "medium",
                  as: "div",
                  children: e.title
                }
              ),
              /* @__PURE__ */ a.jsx(
                s,
                {
                  center: !0,
                  as: "div",
                  children: e.message
                }
              )
            ] })
          ] }),
          /* @__PURE__ */ a.jsxs(i.Footer, { className: r("flex sm:justify-center justify-center gap-6", {
            "flex-row-reverse sm:flex-row-reverse": e.reversActions,
            "flex-row sm:flex-row": !e.reversActions
          }), hideDivider: !0, children: [
            !e.noCancel && /* @__PURE__ */ a.jsx(
              i.Close,
              {
                className: "min-w-32",
                asChild: !0,
                children: /* @__PURE__ */ a.jsx(n, { variant: "outline", children: l })
              }
            ),
            e.onCancel && /* @__PURE__ */ a.jsx(
              n,
              {
                loading: e.loading,
                onClick: e.onCancel,
                variant: "outline",
                className: "min-w-32",
                children: l
              }
            ),
            /* @__PURE__ */ a.jsx(i.Close, { asChild: !0, children: /* @__PURE__ */ a.jsx(
              n,
              {
                className: "min-w-32",
                loading: e.loading,
                onClick: e.onConfirm,
                children: c
              }
            ) })
          ] }),
          e.footer
        ]
      }
    )
  }
);
export {
  v as A
};
